import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';

import ProgressBar from '../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../components/Common/ScrollTop';

// Service Image
import serviceIcon1 from '../../assets/img/service/style7/1.png';
import serviceIcon2 from '../../assets/img/service/style7/2.png';
import serviceIcon3 from '../../assets/img/service/style7/3.png';

import aboutImg1 from '../../assets/img/about/1.jpg';

// FlowChart Image
import Flowchart from '../../assets/img/flowchart/Analytics_solution.png'
import '../../assets/scss/App.scss'


// Choose Us Image
import whyChooseUsImg from '../../assets/img/choose/front-view-laptop-office-workspace-with-lamp-notebook.jpg';
// import whyChooseUsImg from '../../assets/img/choose/3.png';
//helmet
import { Helmet } from 'react-helmet';

const AnalyticSolutionContent = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Data Analytics Solutions | Business Analytics Services in Coimbatore </title>
                <meta name='description' content="Business strategies and technical expertise with our range of services 
        like data,integration ,data visualization,predictive analysis,big data,cloud computing,ML algorithms,
        data driven decisions."/>
                <meta name="keywords" content='data science and analytics,web scrapping,datalake,data migration,data privacy,security,data collection,ML algorithms,Cloud computing,python,bigdata,
        predictive analysis,data visualization,facebook,twitter,instagram,linkedin,google ads'/>
            </Helmet>


            {/* About Section Start */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle="Analytics Solution"
                                titleClass="title"
                                title="Optimize Your Business Decisions with Our Expert Analytics Solutions"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="At Sadhvi Software Solutions, we offer comprehensive analytics solutions designed to enhance your business's decision-making process. Our services include data collection, data analysis, data visualization, and reporting. We help businesses harness the power of data analytics to uncover insights, identify trends, and make informed decisions. Our goal is to empower your business with actionable insights that drive growth and operational efficiency through advanced business intelligence."
                            />
                            {/* Section Title End */}
                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="Providing the best analytic solutions to the clients in sadhvi software solutions, coimbatore." />
                            </div>
                        </div>
                    </div>
                    <div className="btn-part mt-46">
                        <Link to="/contact">
                            <a className="readon learn-more">Contact Us</a>
                        </Link>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    {/* How we do Start */}
                    <SectionTitle
                        titleClass="title"
                        title="Enhance Your Business Intelligence with Our Analytics Solutions"
                        sectionClass="sec-title4"
                        descClass="desc desc-big"
                        description=
                        <p>
                            <strong>Data Analysis:</strong> Utilize advanced analytical techniques to interpret data and uncover meaningful patterns and insights. <br></br> <br></br>

                            <strong>Predictive Analytics:</strong> Use predictive modeling to identify trends, patterns, and correlations in your data, helping you uncover hidden opportunities and make informed decisions. <br></br><br></br>

                            <strong>Data Visualization:</strong> Create intuitive and interactive dashboards and reports to make your data easily understandable and actionable. <br></br><br></br>

                            <strong>Performance Reporting:</strong> Generate detailed reports that track key performance indicators and metrics, providing insights into your progress and success. <br></br><br></br>

                            <strong>Trend Identification:</strong> Identify emerging trends and opportunities to stay ahead of the competition and adapt your strategies accordingly. <br></br><br></br>

                            <strong>Custom Analytics Solutions:</strong> Develop tailored analytics solutions that align with your specific business needs and objectives. <br></br><br></br>

                            <strong>Consulting and Training:</strong> Provide expert consulting and training to empower your team to effectively utilize analytics tools and techniques. <br></br><br></br>

                            <strong>Data Integration:</strong> Integrate data from various sources to create a unified view of your business performance, facilitating comprehensive analysis and decision-making. <br></br><br></br>
                        </p>
                    />
                    {/* How we do End */}

                    {/* Flow Chart Start*/}
                    {/* <div className='container_flowchart'>
                        <div className="image_flowchart">
                            <img src={Flowchart} alt="" />
                        </div>
                    </div> */}
                    {/* Flow Chart Start*/}


                    <div className="rs-services style5 pt-10 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(20deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Expert People"
                                        Text="With experts, we stay ahead of the competition and better assist our clients. "
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(20deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="First Growing Process"
                                        Text="
                                        With new emerging technologies, we develop strategic partnerships in a challenging manner. "
                                    />
                                </div>
                                <div className="col-lg-4" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(20deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Creative Ideas"
                                        Text="Our creative ideas allow us to view and solve problems more openly with innovation. "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br><br></br><br></br>
                    <div>
                        <SectionTitle
                            titleClass="title"
                            title="Why Should You Invest in Analytics Solutions? Unlocking the Benefits"
                            sectionClass="sec-title4"
                            descClass="desc desc-big"
                            description=
                            <p>
                                <strong>Increased Efficiency:</strong> Streamline your operations by identifying areas for improvement and optimizing efficiency to reduce costs. <br></br><br></br>

                                <strong>Enhanced Strategy:</strong> Develop and refine strategies based on actionable data insights to drive growth and achieve your business objectives. <br></br><br></br>

                                <strong>Competitive Advantage:</strong> Gain a competitive edge by leveraging data to identify market trends and customer preferences, helping you stay ahead of the competition. <br></br><br></br>

                                <strong>Improved Customer Understanding:</strong> Understand your customers' behavior and preferences to tailor your offerings and enhance customer satisfaction. <br></br><br></br>

                                <strong>Risk Mitigation:</strong> Identify potential risks and challenges early with data analytics to mitigate their impact and ensure business continuity. <br></br><br></br>

                                <strong>Increased ROI:</strong> Optimize your marketing and operational efforts based on data insights to achieve better returns on investment. <br></br><br></br>

                                <strong>Better Resource Allocation:</strong> Allocate resources more effectively by utilizing data insights to maximize impact and operational efficiency. <br></br><br></br>

                                <strong>Cost-Effective Strategy:</strong> Achieve valuable insights at a fraction of the cost compared to traditional market research methods with our analytics solutions. <br></br><br></br>
                            </p>
                        />
                    </div>
                </div>
            </div>
            {/* About Section End */}

            {/* Why Choose US Section Start */}
            <div className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-46 md-pr-15 md-mb-50">
                            <div className="choose-img">
                                <img className="main" src={whyChooseUsImg} alt="Providing an excellent analytic solutions with appealling model. Startup software company in coimbatore." />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text choose"
                                subtitle="Why Choose Us"
                                titleClass="title pb-20"
                                title="We Are Increasing Business Success With Technology"
                                descClass="desc"
                                description="Sadhvi Software Solutions offers a Services that includes Data Exploration, Data Visualization, and Predictive Analytics. Our experienced team of Data Scientists and Analysts ensure reliable insights to meet your business goals."
                            />
                            {/* Section Title End */}
                            <div className="row">
                                <div className="col-lg-10">
                                    {/* Skill Bar Start */}
                                    <div className="rs-skillbar style1 modify1">
                                        <div className="cl-skill-bar">
                                            <ProgressBar
                                                completed={98}
                                                height={7}
                                                boxbg={"#DFEDE"}
                                                figurebg={"#046b85"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Web Design"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={90}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#046b85"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Web Development"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={90}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#046b85"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Analytics Solution"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={95}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#046b85"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Brand Identity Design"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={95}
                                                height={7}
                                                boxbg={"#DFEDFE"}
                                                figurebg={"#046b85"}
                                                ProgressBox="skillbar"
                                                ProgressFigure="skillbar-bar"
                                                ProgressCount="skill-bar-percent"
                                                ProgressLabel="skillbar-title"
                                                label="Digital Marketing"
                                                ProgressParent="main-div"
                                            />
                                        </div>
                                    </div>
                                    {/* Skill Bar End */}
                                    <div className="btn-part mt-46">
                                        <Link to="/contact">
                                            <a className="readon learn-more">Contact Us</a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Why Choose US Section End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}

export default AnalyticSolutionContent;