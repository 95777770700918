import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../../../components/Common/SectionTitle';
// import SingleService from '../../../../components/Service/SingleService';

// import ProgressBar from '../../../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../../../components/Common/ScrollTop';

// Service Image
// import serviceIcon1 from '../../../../assets/img/service/style7/1.png';
// import serviceIcon2 from '../../../../assets/img/service/style7/2.png';
// import serviceIcon3 from '../../../../assets/img/service/style7/3.png';

import aboutImg1 from '../../../../assets/img/about/online presense.jpg';

import '../../../../assets/scss/App.scss'
//Helmet
import { Helmet } from 'react-helmet';
const SeoContent = () => {
    return (
        <React.Fragment>
        <Helmet>
            <title> SEO Services | On-Page & Local SEO Experts in Coimbatore </title>
            <meta name="description" content="Expert SEO services including on-page optimization, local SEO, technical SEO audits, and content strategy. Boost your website’s ranking on search engines and increase organic traffic with our tailored SEO solutions." />
            <meta name="keywords" content="SEO, on-page SEO, local SEO, technical SEO, SEO services, search engine optimization, organic traffic, keyword research, content optimization, SEO audit, Coimbatore SEO" />
        </Helmet>
            {/* About Section Start */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                // subtitle="Search Engine Optimization"
                                titleClass="title"
                                title="Supercharge Your Online Presence with Our Expert SEO Services"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="At Sadhvi Software Solution, we offer a comprehensive range of professional SEO services that are designed to elevate your online presence and drive organic growth. Our team of skilled SEO experts utilizes proven strategies and industry best practices to help businesses like yours achieve higher search engine rankings, attract targeted traffic, and boost conversions."
                            />
                            {/* Section Title End */}
                            
                        </div>
                        <div className="col-lg-1">
                            <div className="images">
                                
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="images">
                                <img src={aboutImg1} alt="Best Website development company in coimbatore" />
                            </div>
                        </div>
                    </div>
                    <div className="btn-part mt-46">
                        <Link to="/contact">
                            <a className="readon learn-more">Contact Us</a>
                        </Link>
                    </div>
                    <br></br>
                    {/* <br></br>
                    <br></br>
                    <br></br> */}
                   
                    {/* <div className="rs-services style5 pt-10 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Ignite"
                                        Text="Fuel your brand's growth with precise audience targeting and expansive digital reach"
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="Amplify"
                                        Text="Boost brand impact with a powerful online presence and marketing"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Optimize"
                                        Text="Maximize website potential for increased customer engagement and sales"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* About Section End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

        </React.Fragment>

    );
}

export default SeoContent;