import React from 'react';
import { Link } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu, activeMenu } = props;

    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'current-menu-item' : ''}><Link to="" as="/">Home</Link>
            </li>
            <li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
                <Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
            </li>
            <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link href="#" as="#">Services</Link>
                <ul className="sub-menu">
                    {/* <li>
                        <Link to="/service/web-design" className={activeMenu === "/web-design" ? "active-menu" : ""}>Web Design</Link>
                    </li> */}
                    <li>
                        <Link to="/service/web-design" className={activeMenu === "/web-design" ? "active-menu" : ""}>Web Design</Link>
                    </li>
                         <li>
                        <Link to="/service/web-development" className={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link>
                    </li>
                    {/* <li>
                        <Link to="/service/wordpress-development" className={activeMenu === "/wordpress-development" ? "active-menu" : ""}>WordPress Development</Link>
                    </li>   */}
                    <li>
                        <Link to="/service/brand-identity" className={activeMenu === "/brand-identity" ? "active-menu" : ""}>Brand Identity Design</Link>
                    </li>
                    <li>
                        <Link to="/service/analytics-solutions" className={activeMenu === "/analytics-solutions" ? "active-menu" : ""}>Analytics Solutions</Link>
                    </li>
                    <li>
                        <Link to="/service/digital-marketing" lassName={activeMenu === "/digital-marketing" ? "active-menu" : ""}>Strategic Digital Marketing</Link>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'projects' ? 'current-menu-item' : ''}>
                <Link to="/projects" className={activeMenu === "/projects" ? "active-menu" : ""}>Portfolios</Link>
            </li>

            <li className={parentMenu === 'page' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link href="#" className={secondParentMenu === 'case-studies' ? 'active-menu' : ''}>Case studies</Link>
                
                        <ul className="sub-menu right">
                            <li>
                                <Link to="/case-study/style-1" className={activeMenu === "/pneumonia-detection" ? "active-menu" : ""}>Pneumonia Detection and Automatic Ticket classification</Link>
                            </li>
                            <li>
                                <Link to="/case-study/style-2" className={activeMenu === "/case-studies-2" ? "active-menu" : ""}>Product Recommendation System</Link>
                            </li>
                            <li>
                                <Link to="/case-study/style-3" className={activeMenu === "/case-studies-3" ? "active-menu" : ""}>Predicting the term deposit Subscription</Link>
                            </li>
                            <li>
                                <Link to="/case-study/style-4" className={activeMenu === "/case-studies-4" ? "active-menu" : ""}>Website Design for Aksharam International School</Link>
                            </li>
                            <li>
                                <Link to="/case-study/style-5" className={activeMenu === "/case-studies-5" ? "active-menu" : ""}>FORQQ Dealer Management Website Development</Link>
                            </li>
                            <li>
                                <Link to="/case-study/style-6" className={activeMenu === "/case-studies-6" ? "active-menu" : ""}>AI Content Generator Chat-Bot using NLP</Link>
                            </li>
                        </ul>
                    </li>
                    
                
            <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}>
                <Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact Us</Link>
            </li>
        </React.Fragment>
    );
}

export default MenuItems;