import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';



//Custom Components

import Home from '../pages/home';

import About from '../pages/about';
import Projects from '../pages/Products';
import Services from '../pages/service';
import ServiceDetails from '../pages/service/service-details';
import WebDesign from '../pages/service/web-development';
import WebDevelopment from '../pages/service/analytic-solutions';
import WordpressDevelopment from '../pages/service/WordPress/wordpress-development';
import AnalyticSolutions from '../pages/service/machine-learning';
import brandidentity from '../pages/service/BrandIdentity/brand-identity';
import MachineLearning from '../pages/service/machine-learning';
import DigitalMarketing from '../pages/service/DigitalMarketing/digital-marketing';

import seo from '../pages/service/DigitalMarketing/seo/seo';
import sem from '../pages/service/DigitalMarketing/sem/sem';
import smm from '../pages/service/DigitalMarketing/smm/smm';
import Content from '../pages/service/DigitalMarketing/content/content';
import Content1 from '../pages/service/content/content';
import email from '../pages/service/DigitalMarketing/email/email';
import whatsapp from '../pages/service/DigitalMarketing/whatsapp/whatsapp';

import CaseStudtyStyleOne from '../pages/Portfolio/style-1';
import CaseStudtyStyleTwo from '../pages/Portfolio/style-2';
import CaseStudtyStyleThree from '../pages/Portfolio/style-3';
import CaseStudtyStyleFour from '../pages/Portfolio/style-4';
import CaseStudtyStyleFive from '../pages/Portfolio/style-5';
import CaseStudtyStyleSix from '../pages/Portfolio/style-6';
import ProjectOne from '../pages/Portfolio/project-one';
import ProjectTwo from '../pages/Portfolio/project-two';
import ProjectThree from '../pages/Portfolio/project-three';

import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'
import { Helmet } from 'react-helmet';

// new subtab
import logodesign from '../pages/service/web-developmentlogo-design'
// D:\SadhviSoft - Copy (2)\SadhviSoft - Copy\src\pages\service\web-developmentlogo-design.js
import SocialMediaDesign from '../pages/service/social-media-design';
// D:\SadhviSoft - Copy (2)\SadhviSoft - Copy\src\pages\service\social-media-design.js
import WebDesignuiuxdev from '../pages/service/web-designs-ui-ux';
import WordpressDev from '../pages/service/web-development-wp';
import WooCommerce from '../pages/service/web-development-woo';
import BusinessWebsite from '../pages/service/web-development-busweb';
import ECommerceWebSite from '../pages/service/web-development-ews';
import Reactjswebsitedevelopment from '../pages/service/web-development-reactwd';
// new subtab

const App = () => {
    return (
        <div className='App'>
            <Helmet>
                <title>Sadhvi Software Solutions</title>
                <meta name='description' content="Sadhvi software company is a startup software development firm located in coimbatore, specializing in creating custom solution to the needs of small business with our expert and developers in providing quality products with utilising new technologies." />
            </Helmet>

            <Router>
                <LoadTop />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/home" component={Home} />

                    <Route path="/about" component={About} />
                    <Route path="/service" exact component={Services} />

                    {/* newsubtabs */}
                    <Route path="/service/web-design/logo-design/" component={logodesign} />
                    <Route path="/service/web-design/social-media-design/" component={SocialMediaDesign} />
                    <Route path="/service/web-design/web-designs-ui-ux/" component={WebDesignuiuxdev} />
                    <Route path="/service/web-development/wordpress-website-development/" component={WordpressDev} />
                    <Route path="/service/web-development/WooCommerce-website-development/" component={WooCommerce} />
                    <Route path="/service/web-development/business-website-development" component={BusinessWebsite} />
                    <Route path="/service/web-development/ecommerc-website-development" component={ECommerceWebSite} />
                    <Route path="/service/web-development/reactjs-website-development" component={Reactjswebsitedevelopment} />
                    {/* newsubtabs */}
                    <Route path="/service/service-details" component={ServiceDetails} />
                    <Route path="/service/web-design" component={WebDesign} />
                    <Route path="/service/web-development" component={WebDevelopment} />
                    <Route path="/service/wordpress-development" component={WordpressDevelopment} />
                    <Route path="/service/analytic-solutions" component={WebDevelopment} />
                    <Route path="/service/brand-identity" component={brandidentity} />
                    <Route path="/service/analytics-solutions" component={MachineLearning} />
                    <Route path="/service/digital-marketing" component={DigitalMarketing} />

                    <Route path="/service/DigitalMarketing/seo" component={seo} />
                    <Route path="/service/DigitalMarketing/sem" component={sem} />
                    <Route path="/service/DigitalMarketing/smm" component={smm} />
                    <Route path="/service/DigitalMarketing/content" component={Content} />
                    <Route path="/service/AnalyticsAndReport" component={Content1} />
                    <Route path="/service/DigitalMarketing/email" component={email} />
                    <Route path="/service/DigitalMarketing/whatsapp" component={whatsapp} />

                    <Route path="/projects" component={Projects} />

                    <Route path="/case-study/style-1" component={CaseStudtyStyleOne} />
                    <Route path="/case-study/style-2" component={CaseStudtyStyleTwo} />
                    <Route path="/case-study/style-3" component={CaseStudtyStyleThree} />
                    <Route path="/case-study/style-4" component={CaseStudtyStyleFour} />
                    <Route path="/case-study/style-5" component={CaseStudtyStyleFive} />
                    <Route path="/case-study/style-6" component={CaseStudtyStyleSix} />
                    <Route path="/Portfolio/project-one" component={ProjectOne} />
                    <Route path="/Portfolio/project-two" component={ProjectTwo} />
                    <Route path="/Portfolio/project-three" component={ProjectThree} />

                    <Route path="/contact" component={Contact} />
                    <Route component={Error} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
