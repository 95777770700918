import React from 'react';
import { Link } from 'react-router-dom';
//  import OwlCarousel from 'react-owl-carousel2';
import SingleTeam from '../../components/Team/SingleTeam';
import SectionTitle from '../../components/Common/SectionTitle';
// import SingleTeam from '../../components/Team/SingleTeam';
import SingleProcess from '../../components/Process/SingleProcess';


// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage2 from '../../assets/img/process/2.png';
import processImage3 from '../../assets/img/process/3.png';
import processImage4 from '../../assets/img/process/4.png';

// Working Process Images
// import teamImg1 from '../../assets/img/team/1.jpg';
// import teamImg2 from '../../assets/img/team/2.jpg';
// import teamImg3 from '../../assets/img/team/3.jpg';
// import teamImg4 from '../../assets/img/team/4.jpg';
// import teamImg5 from '../../assets/img/team/5.jpg';
// import teamImg6 from '../../assets/img/team/6.jpg';
// import teamImg7 from '../../assets/img/team/7.jpg';
// import teamImg8 from '../../assets/img/team/7.jpg';

// import aboutImg from '../../assets/img/about/about-3.png';
// changes 
import aboutImg from '../../assets/img/newimges/stock-market-exchange-economics-investment-graph.jpg';
import aboutImg1 from '../../assets/img/newimges/3d-illustration-boy-with-camera-his-hand.jpg';
import aboutImg2 from '../../assets/img/newimges/3d-rendering-cartoon-character-with-helmet-overalls.jpg';
import aboutImg3 from '../../assets/img/newimges/3d-illustration-boy-with-camera-his-hand.jpg';
import danceEffectImg from '../../assets/img/about/effect-1.png';



import effectImg1 from '../../assets/img/about/dotted-3.png';
import effectImg2 from '../../assets/img/about/shape3.png';


import { Helmet } from 'react-helmet';

const AboutMain = () => {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: true,
            },
            992: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

    return (

        <React.Fragment>
            <Helmet>
                <title>Web dore</title>
                <meta name='description' content="A startup software firm located in coimbatore also referred as a women owned company which is excellent in 
            designing and developing website according to the customer satisfaction, their needs."/>
                <meta name="keywords" content='Top 10,startup company,designing developing.analytic solutions,digital marketers,clients,discover,
            plan,execute,partnership,experts'/>
            </Helmet>
            {/* about-area-start */}
            <div id="rs-about" className="rs-about gray-bg pt-120 md-pt-80 md-pb-80" style={{ paddingBottom: '51px' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 md-mb-30">
                            <div className="rs-animation-shape">
                                <div className="images">
                                    <img src={aboutImg} alt="Best startup software company in coimbatore" />
                                </div>
                                {/* <div className="middle-image2">
                                    <img className="dance" src={danceEffectImg} alt="" />
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    // subtitle="About Us"
                                    titleClass="title pb-36"
                                    title="We Are Increasing Business Success With Technology"
                                    descClass="desc pb-34"
                                    description="Sadhvi Software Solutions is a start-up company that provides the best statics in designing and developing powerful solutions for your success."
                                    secondDescClass="desc pb-34"
                                    secondDescription="We are providing Website Development Services, and Data Analytics solutions to our clients. Our team of Developers, Data Analysts, and Digital Marketers are focused in helping our clients to gain a competitive edge in respective markets. 
                                    We help our clients to stand better in the market by providing the best solutions and support."
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/contact">Learn-More</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <div className="shape-image">
                        <img className="top dance" src={effectImg1} alt="" />
                        <img className="bottom dance" src={effectImg2} alt="" />
                    </div> */}
                </div>
            </div>
            {/* about-area-end */}

            {/* working-process-area-start */}
            <div className="rs-process style2 pt-120 pb-112 md-pt-80 md-pb-72">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        // subtitle="Process"
                        titleClass="title title2"
                        title="Our Working Process"
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage2}
                                titleClass="title"
                                Title="Plan"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage3}
                                titleClass="title"
                                Title="Execute"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage4}
                                titleClass="title"
                                Title="Deliver"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}

            {/* our Team  */}
            <div className="rs-team modify1 pt-120 pb-94 md-pt-80 md-pb-74">
    <div className="container">
        <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            // subtitle="Team"
            titleClass="title title2"
            title="Our Team"
        />
        <div className="row">
            <div className="col-lg-4 col-md-6 mb-50">
                <SingleTeam
                    itemClass="team-item-wrap"
                    teamImage={aboutImg1}
                    teamURL="makhaia-antitni"
                    Title="Satish"
                    Designation="CEO & Founder"
                    style={{ width: '100%', height: 'auto', borderRadius: '50%' }}
                />
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
                <SingleTeam
                    itemClass="team-item-wrap"
                    teamImage={aboutImg1}
                    teamURL="corey-anderson"
                    Title="Sasi Kumar"
                    Designation="HR"
                    style={{ width: '100%', height: 'auto', borderRadius: '50%' }}
                />
            </div>
            <div className="col-lg-4 col-md-6 mx-auto d-flex justify-content-center">
                <SingleTeam
                    itemClass="team-item-wrap"
                    teamImage={aboutImg2}
                    teamURL="sonia-akhter"
                    Title="Dhivya Barathi"
                    Designation="Graphic Artist"
                    style={{ width: '100%', height: 'auto', borderRadius: '50%' }}
                />
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
                <SingleTeam
                    itemClass="team-item-wrap"
                    teamImage={aboutImg2}
                    teamURL="corey-anderson"
                    Title="Angeline Swegha"
                    Designation="Developer"
                    // style={{ width: '100%', height: 'auto', borderRadius: '50%' }}
                />
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
                <SingleTeam
                    itemClass="team-item-wrap"
                    teamImage={aboutImg1}
                    teamURL="corey-anderson"
                    Title="karthikeyan"
                    Designation="Developer"
                    // style={{ width: '100%', height: 'auto', borderRadius: '50%' }}
                />
            </div>
            <div className="col-lg-4 col-md-6 mx-auto d-flex justify-content-center">
                <SingleTeam
                    itemClass="team-item-wrap"
                    teamImage={aboutImg3}
                    teamURL="sonia-akhter"
                    Title="Lokesh"
                    Designation="Front End Developer"
                    // style={{ width: '100%', height: 'auto', borderRadius: '50%' }}
                />
            </div>
        </div>
    </div>
</div>

            
    

        </React.Fragment>
    )
}

export default AboutMain;