import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {

	const [home, setHome] = useState(false)
	const [homeMultipage, setHomeMultipage] = useState(false)
	const [homeOnepage, setHomeOnepage] = useState(false)
	const [about, setAbout] = useState(false)
	const [services, setServices] = useState(false)
	const [blog, setBlog] = useState(false)
	const [pages, setPages] = useState(false)
	const [servicePages, setServicePages] = useState(false)
	const [caseStudies, setCaseStudies] = useState(false)
	const [shopPages, setShopPages] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(!about)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'services') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(!services)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(!blog)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'pages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(!pages)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'servicePages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(!servicePages)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'caseStudies') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(false)
			setCaseStudies(!caseStudies)
			setShopPages(false)
		}
		else if (menu === 'shopPages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(!shopPages)
		}
	};

	return (
		<div className="container relative">
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li>
							<Link to="/" className={activeMenu === "/" ? "active-menu" : ""}>Home</Link>
						</li>
						<li>
							<Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
						</li>
						<li className={services ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="#" onClick={() => { openMobileMenu('services'); }}>Services</Link>
							<ul className={services ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="/service/web-design" className={activeMenu === "/web-design" ? "active-menu" : ""}>Web Design</Link>
								</li>
								<li>
									<Link to="/service/web-development" className={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link>
								</li>
								{/* <li>
									<Link to="/service/analytic-solutions" className={activeMenu === "/analytic-solutions" ? "active-menu" : ""}>Analytics Solutions</Link>
								</li> */}
								<li>
									<Link to="/service/brand-identity" className={activeMenu === "/brand-identity" ? "active-menu" : ""}>Brand Identity Design</Link>
								</li>
								<li>
									<Link to="service/analytics-solutions" className={activeMenu === "/analytics-solutions" ? "active-menu" : ""}>Analytics Solutions</Link>
								</li>
								<li>
									<Link to="/service/digital-marketing" className={activeMenu === "/digital-marketing" ? "active-menu" : ""}>Strategic Digital Marketing</Link>
								</li>
							</ul>
						</li>


						<li className={pages ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="#" onClick={() => { openMobileMenu('caseStudies'); }}>Portfolios</Link>

							<ul className={caseStudies ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="/case-study/style-1" className={activeMenu === "/case-studies-1" ? "active-menu" : ""}>Pneumonia Detection and Automatic Ticket classification</Link>
								</li>
								<li>
									<Link to="/case-study/style-2" className={activeMenu === "/case-studies-2" ? "active-menu" : ""}>Product Recommendation System</Link>
								</li>
								<li>
									<Link to="/case-study/style-3" className={activeMenu === "/case-studies-3" ? "active-menu" : ""}>Predicting the term deposit Subscription</Link>
								</li>
								<li>
									<Link to="/case-study/style-4" className={activeMenu === "/case-studies-4" ? "active-menu" : ""}>Website Design for Aksharam International School</Link>
								</li>
								<li>
									<Link to="/case-study/style-5" className={activeMenu === "/case-studies-5" ? "active-menu" : ""}>FORQQ Dealer Management Website Development</Link>
								</li>
								<li>
									<Link to="/case-study/style-6" className={activeMenu === "/case-studies-6" ? "active-menu" : ""}>AI Content Generator Chat-Bot using NLP </Link>
								</li>
							</ul>
						</li>
						<li>
							<Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact Us</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;