import React from 'react';
import About from './AboutSection';
import Service from './ServiceSection';
import CallUs from './CallUsSection';
import Process from './ProcessSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import BannerDefault from '../../components/Banner/BannerDefault';
import Testimonial from '../service/DigitalMarketing/TestimonialSection';
import FaqMain from '../service/DigitalMarketing/Faq';
// import ContactMain from './ContactMain';
import ContactMain from '../../src/pages/service/DigitalMarketing/ContactMain';
// import CounterDefault from '../../components/Elements/Counter/CounterDefault';
const HomeMain = () => {
	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerDefault />
			{/* banner-start */}

			{/* services-area-start */}
			<Service />
			{/* services-area-end */}

			{/* about-area-start */}
			<About />
			{/* about-area-end */}

			{/* TESTMONIAL */}
			<Testimonial />
			{/* testmonial */}

			{/* call us section start */}
			<CallUs />
			{/* call us section end */}

			{/* TESTMONIAL */}
			{/* <FaqMain /> */}
			{/* <CounterDefault /> */}
			{/* testmonial */}

			{/* process-area-start */}
			<Process />
			{/* process-area-end */}
			{/* contact */}
			<ContactMain />
			{/* contact */}
			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeMain;