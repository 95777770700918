import React from 'react';
import Header from '../../../../components/Layout/Header';
import Footer from '../../../../components/Layout/Footer';
import SearchModal from '../../../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../../../components/Common/Breadcumb';

import Service from './ServiceSection';
import WhyChooseUs from './WhyChooseUsSection';
import CTA from '../../../../components/Common/CTA';
import { Helmet } from 'react-helmet';
// Breadcrumbs Image
import breadcrumbsImg from '../../../../assets/img/breadcrumbs/digitalmark/4.jpg';
import ContentMarketingContent from './ContentMarketingContent';

const whatsapp = () => {
    return (
        <React.Fragment>
        <Helmet>
            <title> Content Marketing Services | Blog & SEO Content Writing in Coimbatore </title>
            <meta name="description" content="Content marketing services including blog content creation, article writing, and SEO content strategies. We help businesses attract and engage their target audience with high-quality, optimized content." />
            <meta name="keywords" content="content marketing, blog writing, SEO content, article writing, content strategy, content creation, copywriting, content marketing services, Coimbatore content marketing" />
        </Helmet>

            <Header
                parentMenu='service'                
                activeMenu='/content-marketing'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Content Marketing'               
                pageName='Content Marketing'
            />
            {/* breadcrumb-area-end */}

            {/* SeoContent-area-start */}
            <ContentMarketingContent />
            {/* SeoContent-area-end */}

            {/* Service-area-start */}
            <Service />
            {/* Service-area-end */}

            {/* WhyChooseUs-area-start */}
			<WhyChooseUs />
			{/* WhyChooseUs-area-end */}

            {/* newsletter-area-start */}
			<CTA
                ctaSectionClass="rs-cta style1 cta-bg1 pt-70 pb-70"
                ctaTitleClass="epx-title"
                ctaTitle="Supercharge Your Online Success with Professional Content Marketing Services!"
                ctaButtonClass="readon learn-more"
                ctaButtonLink="/contact"
                ctaButtonText="Get In Touch"
            />
            {/* newsletter-area-end */}
         
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default whatsapp;