import React from 'react';
import Header from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import SearchModal from '../../../components/Layout/Header/SearchModal';

import BannerDigitalMarketing from '../../../components/Banner/BannerDigitalMarketing';
import Brand from './BrandSection';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import About from './AboutSection';
import ServiceFlip from './ServiceSectionFlip';
import Testimonial from './TestimonialSection';
import FaqMain from '../DigitalMarketing/Faq';
import ContactMain from './ContactMain';
import { Helmet } from 'react-helmet';
// Breadcrumbs Image
import breadcrumbsImg from '../../../assets/img/breadcrumbs/6.jpg';


const DataCenter = () => {
    return (
        <React.Fragment>
        <Helmet>
                <title> Digital Marketing Services | Marketing & SEO Company in Coimbatore </title>
                <meta name="description" content="Professional digital marketing services including SEO, SEM, social media marketing, and content marketing. We help businesses grow online with targeted strategies, paid ads, and effective social media management for increased visibility and engagement." />
                <meta name="keywords" content="digital marketing, SEO, SEM, social media marketing, content marketing, search engine optimization, Google Ads, PPC, paid advertising, Facebook ads, Instagram marketing, LinkedIn marketing, online marketing, digital marketing agency, email marketing, marketing strategy" />
        </Helmet>
            <Header
                parentMenu='service'
                activeMenu='/data-center'
            />

            {/* Banner-start */}
            <BannerDigitalMarketing />
            {/* Banner-end */}

            {/* About-area-start */}
			<About />
			{/* About-area-end */}

            <ServiceFlip />

            {/* Testimonial-start */}
            <Testimonial />
            {/* Testimonial-end */}

            {/* Faq */}
            <FaqMain />
            {/* Faq */}

            {/* ContactMain Section Start */}
            <ContactMain />
            {/* ContactMain Section End */}

            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default DataCenter;