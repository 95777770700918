import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../../../components/Common/SectionTitle';
// import SingleService from '../../../../components/Service/SingleService';

import ScrollToTop from '../../../../components/Common/ScrollTop';

// Service Image
// import serviceIcon1 from '../../../../assets/img/service/style7/1.png';
// import serviceIcon2 from '../../../../assets/img/service/style7/2.png';
// import serviceIcon3 from '../../../../assets/img/service/style7/3.png';

import aboutImg1 from '../../../../assets/img/about/sem.png';

import '../../../../assets/scss/App.scss'
//Helmet
import { Helmet } from 'react-helmet';
const SemContent = () => {
    return (
        <React.Fragment>
        <Helmet>
            <title> SEM Services | Google Ads & Paid Search Advertising in Coimbatore </title>
            <meta name="description" content="Professional SEM services including Google Ads management, pay-per-click advertising (PPC), and campaign optimization. Drive traffic, increase conversions, and maximize your ROI with our SEM strategies." />
            <meta name="keywords" content="SEM, search engine marketing, Google Ads, PPC, pay-per-click, paid advertising, SEM services, campaign optimization, Coimbatore SEM, PPC management, digital advertising" />
        </Helmet>
            {/* About Section Start */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                // subtitle="Search Engine Marketing"
                                titleClass="title"
                                title="Supercharge Your Online Presence with Our Expert SEM Services"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="At Sadhvi Software Solution, we understand that a successful online marketing strategy requires a combination of organic search engine optimization (SEO) and paid search advertising. Our team of SEM experts utilizes industry best practices and cutting-edge techniques to deliver outstanding results for your business."
                            />
                            {/* Section Title End */}
                            
                        </div>
                        <div className="col-lg-1">
                            <div className="images">
                                
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="images">
                                <img src={aboutImg1} alt="Best Website development company in coimbatore" />
                            </div>
                        </div>
                    </div>
                    <div className="btn-part mt-46">
                        <Link to="/contact">
                            <a className="readon learn-more">Contact Us</a>
                        </Link>
                    </div>
                    {/* <br></br>
                    <br></br>
                    <br></br>
                    <br></br> */}
                   
                    {/* <div className="rs-services style5 pt-10 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Ignite"
                                        Text="Fuel your brand's growth with precise audience targeting and expansive digital reach"
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="Amplify"
                                        Text="Boost brand impact with a powerful online presence and marketing"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Optimize"
                                        Text="Maximize website potential for increased customer engagement and sales"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* About Section End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

        </React.Fragment>

    );
}

export default SemContent;